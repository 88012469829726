import React from 'react'
import { Select as SelectCore, SelectProps as SelectCoreProps } from '@chakra-ui/select'
export type SelectProps = SelectCoreProps & {
  ref?: any
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { ...otherProps } = props
  return <SelectCore ref={ref} {...otherProps} />
})

Select.displayName = 'Select'
