import moment from 'dayjs'
import { useEffect, useState } from 'react'

export default function useShowTime(time: string | number) {
  const [formattedDate, setFormatedDate] = useState('刚刚')

  useEffect(() => {
    if (time) {
      const formatDate = () => {
        const date = new Date(time)
        const now = new Date()

        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)

        const yearNow = moment(now).format('YYYY')
        const yearTime = moment(time).format('YYYY')
        if (yearNow !== yearTime) {
          return moment(time).format('YYYY年MM月DD日')
        }

        let interval = Math.floor(seconds / 86400)
        if (interval >= 1 && interval <= 7) {
          return interval + ' 天前'
        } else if (interval >= 7) {
          return moment(time).format('MM月DD日')
        }

        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          return interval + ' 小时前'
        }
        interval = Math.floor(seconds / 60)
        if (interval >= 1) {
          return interval + ' 分钟前'
        }

        return '刚刚'
      }

      setFormatedDate(formatDate())
    } else {
      setFormatedDate(null)
    }
  }, [])

  return formattedDate
}
