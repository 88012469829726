import React from 'react'
import { Box } from '../Box'
import { AppContainer } from '../AppContainer'
import { Link } from '../Link'
import { Button } from '../Button'

export function NotFoundSection() {
  const detail = (
    <Box pt='60px' pb='100px'>
      <AppContainer maxWidth='1000px'>
        <Box
          width='100%'
          maxWidth='800px'
          mx='auto'
          display='block'
          as='img'
          src='https://static.letschuhai.com/static/images/graphic_404.svg'
          alt=''
        />
        <Box mt='20px' textAlign='center' as='h1' textStyle='pageTitle'>
          404 错误
        </Box>
        <Box mt='10px' textAlign='center' as='h2' fontSize='24px' lineHeight='40px'>
          抱歉，但您输入的网址似乎不存在或已经被删除!
        </Box>
        <Box mt='30px' textAlign='center'>
          <Link noStyle={true} href='/'>
            <Button variant='lg'>返回首页</Button>
          </Link>
        </Box>
      </AppContainer>
    </Box>
  )

  return <React.Fragment>{detail}</React.Fragment>
}
