import { Pulse } from '@krasia/core/types'
import PulsesList from './PulsesList'
import { Box } from '../Box'
import { Text } from '@chakra-ui/layout'
import { PulseIcon } from '../../icons'
import { useMediaQuery } from '@chakra-ui/react'

interface PulseSectionProps {
  title: string
  list: Pulse[]
}

const PulseSection: React.FC<PulseSectionProps> = ({ title, list }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  }) // 用于判断是否是移动端

  if (isMobile) {
    return null
  }

  return (
    <Box overflowX={'hidden'}>
      <Box mb={'24px'} display={'flex'} alignItems={'center'}>
        <Text as='h2' textStyle='sectionTitle' display='flex' alignItems='center'>
          <Box mt='2px' fontSize='1em' mr='16px'>
            <PulseIcon />
          </Box>
          <Box as='span'>{title}</Box>
        </Text>
      </Box>
      <Box flex={1} height={{ base: '100%', md: '560px' }} overflow={'auto'}>
        <PulsesList list={list} isFullList={false} />
      </Box>
      <Box
        height={'80px'}
        mt={'-80px'}
        // background image, linear gradient
        backgroundImage={'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #212832 60%)'}
        position={'relative'}
        zIndex={2}
        display={'flex'}
        alignItems={'flex-end'}
        justifyContent={'center'}
      >
        <Box
          as='button'
          onClick={() => {
            window.location.href = '/pulses'
          }}
          border={'1px solid #66FCF1'}
          color={'#66FCF1'}
          padding={'4px  8px'}
          width={'60%'}
          transition={'all 0.3s ease-in-out'}
          _hover={{
            backgroundColor: '#66FCF1',
            color: '#000'
          }}
        >
          查看全部
        </Box>
      </Box>
    </Box>
  )
}

export default PulseSection
