import React from 'react'
import { FormHelperText as FormHelperTextCore, HelpTextProps as FormHelperTextPropsCore } from '@chakra-ui/form-control'

export type FormHelperTextProps = FormHelperTextPropsCore

export const FormHelperText = React.forwardRef<HTMLElement, FormHelperTextProps>((props, ref) => {
  const { ...otherProps } = props
  return <FormHelperTextCore ref={ref as any} {...otherProps} />
})

FormHelperText.displayName = 'FormHelperText'
