import React from 'react'
import Link from 'next/link'

export function ErrorSection() {
  return (
    <div>
      <h1>Error</h1>
      <Link href='/' prefetch={false}>
        Home
      </Link>
    </div>
  )
}
