import { Box } from '@chakra-ui/layout'
import { SystemProps } from '@chakra-ui/styled-system'
import React from 'react'
import { Ad } from '../../types'
import { AppContainer } from '../AppContainer'
import useIntersectionObserver from '@react-hook/intersection-observer'
import Image from 'next/image'

export type AddSectionPosition = 'alone' | 'asideTop'

export type AddSectionProps = {
  onViewed?: () => void
  rootProps?: SystemProps
  linkProps?: SystemProps
  ad: Ad
  position: AddSectionPosition
  positionName?: string
}

export function AdSection({ ad, position, positionName, rootProps = {}, linkProps = {}, onViewed }: AddSectionProps) {
  const [ref, setRef] = React.useState<HTMLAnchorElement | null>(null)
  const [viewed, setViewed] = React.useState(false)
  const { isIntersecting } = useIntersectionObserver(ref, {
    threshold: 1
  })

  React.useEffect(() => {
    if (!viewed && isIntersecting && onViewed) {
      setViewed(true)
      onViewed()
    }
  }, [isIntersecting, viewed, onViewed])

  let styles: SystemProps = {
    display: 'block',
    mx: 'auto'
  }

  let stylesContainer: SystemProps = {
    pt: { base: '0px', md: '40px' },
    pb: { base: '0px', md: '40px' },
    pl: { base: '16px', sm: '50px', md: '50px' },
    pr: { base: '16px', sm: '50px', md: '50px' }
  }

  // let imageStyles: SystemProps = {
  //   height: 'inherit'
  // }

  if (position === 'asideTop') {
    stylesContainer = {
      ...stylesContainer,
      pt: { base: '0' },
      pb: { base: '30px' },
      pl: { base: '0' },
      pr: { base: '0' }
    }
    styles = {
      ...styles,
      width: '100%',
      paddingInline: { base: 0 }
    }

    // imageStyles = {
    //   ...imageStyles
    // }
  }

  if (position === 'alone') {
    styles = {
      ...styles,
      width: '100%',
      paddingInline: { base: 0, md: '35px' }
      // maxW: '1140px'
    }
  }

  if (positionName === 'latest-top') {
    stylesContainer = {
      ...stylesContainer,
      width: '100%',
      pt: { base: '0' },
      pb: { base: '30px' },
      pl: { base: '0' },
      pr: { base: '0' }
    }
  }

  if (positionName === 'mobile-article-nav-bottom') {
    stylesContainer = {
      ...stylesContainer,
      width: '100%',
      pt: { base: '0' },
      pb: { base: '0' },
      pl: { base: '0' },
      pr: { base: '0' }
    }
  }

  if (
    positionName === 'collection-aside' ||
    positionName === 'recent-aside' ||
    positionName === 'recent-aside-2' ||
    positionName === 'article-aside' ||
    positionName === 'article-aside-2' ||
    positionName === 'collection-aside-all' ||
    positionName === 'collection-aside-all-2'
  ) {
    stylesContainer = {
      ...stylesContainer,
      pt: { base: '0' },
      pb: { base: '30px' },
      pl: { base: '0' },
      pr: { base: '0' }
    }
    styles = {
      ...styles,
      width: '100%',
      paddingInline: { base: 0 }
    }
  }

  if (positionName === 'above-last-section' || positionName === 'featured-bottom') {
    stylesContainer = {
      ...stylesContainer,
      pt: { base: '50px', md: '60px' },
      pb: { base: '50px', md: '60px' }
    }
  }

  return (
    <AppContainer {...stylesContainer} {...rootProps}>
      <Box
        {...styles}
        as='a'
        href={ad.link}
        title={ad.label}
        {...linkProps}
        ref={setRef as any}
        rel={'nofollow'}
        target='_blank'
      >
        <Image
          src={ad.img.url}
          width={ad.img.width}
          height={ad.img.height}
          layout='responsive'
          alt={'广告图片'}
          priority={true}
        />
      </Box>
    </AppContainer>
  )
}
