import React from 'react'
import { Checkbox as CheckboxCore, CheckboxProps as CheckboxCoreProps } from '@chakra-ui/checkbox'

export type CheckboxProps = CheckboxCoreProps

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { ...otherProps } = props
  return <CheckboxCore ref={ref} size='lg' {...otherProps} />
})

Checkbox.displayName = 'Checkbox'
