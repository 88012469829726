import React, { useEffect } from 'react'
import { Box } from '../Box'
import { NotificationsResult } from '../../fetchers/post'
import { Alert, AlertDescription, CloseButton, Link } from '@chakra-ui/react'
import Cookies from 'js-cookie'

export type PostNotifications = {
  id: number
  notification: NotificationsResult
  headerOnBottom?: boolean
}

export function PostNotifications(props: PostNotifications) {
  const { id, notification, headerOnBottom } = props
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    if (notification && typeof window !== 'undefined') {
      const notificationExpired = Cookies.get('notificationExpired')
      if (!notificationExpired) {
        setShow(true)
      }
    }
  }, [notification])

  const onClose = () => {
    setShow(false)
    Cookies.set('notificationExpired', 'true', { expires: 1 })
  }

  return (
    <>
      {show && id != notification.id && (
        <Alert
          status='info'
          variant='solid'
          // justifyContent={'space-between'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={'24px'}
          position={'sticky'}
          top={!headerOnBottom ? '64px' : '16px'}
          zIndex={'1'}
          transition={'all ease-in-out .3s'}
        >
          <Link href={notification.slug}>
            <Box>
              <AlertDescription fontSize={'13px'}>{notification.text}</AlertDescription>
            </Box>
          </Link>
          <CloseButton alignSelf='flex-end' position='absolute' onClick={onClose} right={'4px'} top={'4px'} />
        </Alert>
      )}
    </>
  )
}
